import React from "react";
import BoxHoc from "../../hoc/BoxHoc";
import Table from "../Table";

import SeeAll from "../SeeAll";
import "./styles.scss";

const TableBox = ({
  width,
  oboardFilter,
  oboardFilter1,
  onPageChange,
  searchValue,
  headers = [],
  data = [],
  options1 = [],
  options2 = [],
  optionValue1,
  optionValue2,
  paginationInfo,
  onSearchChange,
  enablePagination,
  handleSaveFilterVal,
  isOnBoardFilter = false,
  forRewardAccess = false,
  forTransactions = false,
  isSortByFilter = false,
  isTransactionFilter = false,
  selectTwoType = "Sort By",
  handleDateChange,
  isDateRange = false,
  companysOnboard,
  mediaAgencies,
  subscribers,
  title,
  navTo,
}) => {
  return (
    <div className="table-box">
      <BoxHoc
        className={forTransactions ? "transaction-padding" : "padding-adjust"}
      >
        {!enablePagination && !forRewardAccess && (
          <div className="flex space-between">
            <div className="mediumUpper pl-16">{ title }</div>
            <SeeAll navTo={navTo} />
          </div>
        )}

        <Table
          width={width}
          data={data}
          headers={headers}
          options1={options1}
          options2={options2}
          {...paginationInfo}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          searchValue={searchValue}
          oboardFilter={oboardFilter}
          oboardFilter1={oboardFilter1}
          onPageChange={onPageChange}
          optionValue1={optionValue1}
          optionValue2={optionValue2}
          selectTwoType={selectTwoType}
          onSearchChange={onSearchChange}
          forRewardAccess={forRewardAccess}
          isOnBoardFilter={isOnBoardFilter}
          enablePagination={enablePagination}
          handleSaveFilterVal={handleSaveFilterVal}
          forTransactions={forTransactions}
          isSortByFilter={isSortByFilter}
          handleDateChange={handleDateChange}
          isDateRange={isDateRange}
          companysOnboard={companysOnboard}
          isTransactionFilter={isTransactionFilter}
          mediaAgencies={mediaAgencies}
          subscribers={subscribers}
        />
      </BoxHoc>
    </div>
  );
};

export default TableBox;
