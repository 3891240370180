import Axios from "axios";
import axios from "../utilities/axios";
import { isNull } from "lodash";
import { API_SERVER_URL } from "../config";

// About me

export const getAboutMe = async () => {
  return axios.get(`/user/aboutMe`);
};

// Media Agency -- Role -> Super Admin

export const addMediaAgency = (payload, mediaId) => {
  if (isNull(mediaId)) return axios.post("/mediaAgency", payload);
  else return axios.put(`/mediaAgency/${mediaId}`, payload);
};

export const getMediaAgencyById = (mediaId) => {
  return axios.get(`/mediaAgency/${mediaId}`);
};

export const getWalletDetailById = (id) => {
  return axios.get(`/userCryptoAccount/${id}`);
}

export const getTrasactionById = (id) => {
  return axios.get(`/withdraw/${id}`);
}

export const updateTransactionById = (payload, mediaId) => {
  return axios.put(`/withdraw/${mediaId}/statusChange`, payload);
}

export const deleteWalletAccountById = (id) => {
  return axios.delete(`/userCryptoAccount/${id}`)
}

export const fetchMediaAgencyListing = (params) => {
  return axios.get(
    `/mediaAgency?sortBy=${params?.sortBy}&limit=${params?.show}&agencySearch=${(params?.search || params?.mediaAgency)}&page=${params?.page}`
  );
};


export const fetchAllMediaAgencies = (params) => {
  return axios.get(
    `/mediaAgency/dropdown?limit=${params?.limit}&page=${params?.page}`
  );
};

export const fetchSubscribersListing = (params) => {
  return axios.get(
    `/subscriber/dropdown?limit=${params?.limit}&page=${params?.page}`
  );
};

export const fetchWalletAccountListing = (params) => {
  return axios.get(
    `/userCryptoAccount?userId=${params?.userId}&email=${params.email}&limit=${params?.show}&page=${params?.page}`
  )
}

export const addWalletAccount = (payload, mediaId) => {
  if (isNull(mediaId)) return axios.post("/userCryptoAccount", payload);
  else return axios.put(`/userCryptoAccount/${mediaId}`, payload);
}

// Settings

export const fecthSettings = () => {
  return axios.get("/settings");
};

export const fetchAccountNames = () => {
  return axios.get("/userCryptoAccount/account-names")
}

export const saveSettings = (id, payload) => {
  return axios.put(`/settings/${id}`, payload);
};

// Activity log

export const fetchActivityLogs = (params, user) => {
  if (user?.role === "MEDIA_AGENCY") {
    return axios.get(
      `/activityLog?agencyId=${user?._id}&limit=${params?.show}&search=${params?.search}&company=${params?.company}&subscriber=${params?.subscriber}&eventType=${params?.type}&page=${params?.page}`
    );
  } else
    return axios.get(
      `/activityLog?limit=${params?.show}&search=${params?.search}&company=${params?.company}&subscriber=${params?.subscriber}&eventType=${params?.type}&page=${params?.page}`
    );
};

// dashboard
export const fetchDashboardStats = (timeFrame, startDate, endDate) => {
  return axios.get(
    `/dashboard?filterFlag=${timeFrame.toUpperCase()}&startDate=${startDate}&endDate=${endDate}`
  );
};

// login
export const loginUser = (data) => {
  const payload = {
    email: data?.email,
    password: data?.password,
  };
  return axios.post(`/auth/login`, payload);
};

// account
export const accountUpdate = (payload, isAdmin, id = "") => {
  if (isAdmin) {
    return axios.put(`/user/${id}`, payload);
  }
  if (!isAdmin) {
    return axios.put(`/mediaAgency/changeAccountInfo/${id}`, payload);
  }
};

// change password
export const changePassword = (payload, id = "") => {
  return axios.put(`/user/changePassword/${id}`, payload);
};

// reset password
export const sendResetPasswordEmil = (payload) => {
  return axios.post(`/user/requestPasswordReset`, payload);
};

export const resetPasswordByToken = (payload) => {
  return axios.put(`/user/resetPassword`, payload);
};

// Media dashb oard

export const fetchMediaDashboard = (timeFrame, startDate, endDate, id) => {
  return axios.get(
    `/dashboard/agencyDashboard?filterFlag=${timeFrame.toUpperCase()}&startDate=${startDate}&endDate=${endDate}&id=${id}`
  );
};

// subscribers

export const fetchSubscribers = (params) => {
  return axios.get(
    `/subscriber?agencyId=${params?.agencyId}&sortBy=${params?.sortBy}&limit=${params?.show}&search=${params?.search}&page=${params?.page}&startDate=${params?.startDate}&endDate=${params?.endDate}`
  );
};

export const updateSubscriberStatus = (id, payload) => {
  return axios.put(`/subscriber/${id}`, payload);
};

export const fetchWalletDetail = (id) => {
  return axios.get(`/subscriber/subscriberWallet/${id}`);
};

// Media account update

export const mediaAccountUpdate = (id, payload) => {
  return axios.put(`/mediaAgency/changeAccountInfo/${id}`, payload);
};

//  Video Article

export const fetchVideoArticle = (params) => {
  return axios.get(
    `/content?agencyId=${params?.id}&contentCategory=${params?.contentType
    }&sortBy=${params?.sortBy}&limit=${+params?.show}&search=${params?.search
    }&page=${+params?.page}`
  );
};

//  Keys

export const generateKeys = (id) => {
  return axios.post(`/mediaAgency/keypair/${id}`);
};

export const fetchKeys = (id) => {
  return axios.get(`/mediaAgency/keypair/${id}`);
};

// img uploader

export const getImgKey = (formData) => {
  return Axios.post(`${API_SERVER_URL}/media/upload`, formData);
};

// reward access

export const fetchRewardAccess = (id) => {
  return axios.get(`/mediaAgency/rewardAccess/${id}`);
};

export const updateRewardAccess = (id, payload) => {
  return axios.put(`/mediaAgency/rewardAccess/${id}`, payload);
};

// withdrawal request
export const createWithdraw = (payload) => {
  return axios.post("/withdraw", payload);
};

// transaction listing
export const fetchTransactionListing = (payload) => {
  return axios.post(
    `/withdraw/listing`, payload
  );
};
