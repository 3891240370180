import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import Image from "../Image";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "react-tooltip";

const CompanyWithSubs = ({
  agency,
  name,
  email,
  hash,
  hashStatus = false,
  avatar = "",
  navigateTo,
  defaultSrc = "",
  isEmailUnder = false,
  isNavigate = true,
  isAgencyShow = false,
  isVideoArticle = false,
  showImage = true,
}) => {
  let navigate = useNavigate();
  const [copyStatus, setCopyStatus] = useState(false);

  const handleGoTo = () => {
    if (isNavigate) {
      navigate(navigateTo);
    } else navigateTo();
  };

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 1000);
  };

  return (
    <div className="comp-with-subs flex" onClick={handleGoTo}>
      {showImage && (
        <Image
          height="36px"
          imgSrc={avatar}
          isCompany={false}
          representative={name}
          className="table-img"
          defaultSrc={defaultSrc}
          isVideoArticle={isVideoArticle}
        />
      )}
      <div className="flex-d-colm">
        {isAgencyShow && <span className="agency-t">{agency}</span>}
        <span className="sixty-twelve one-line-txt" title={name}>
          {name}
        </span>
        {hashStatus && (
          <div style={{ display: "flex", gap: ".2rem" }}>
            <span className="hash email">{hash}</span>
            <CopyToClipboard
              text={hash}
              onCopy={onCopyText}
              data-tooltip-id="myTooltip"
              data-tooltip-content={
                !copyStatus ? "Copy Transaction Hash" : "Copied!"
              }
            >
              {!copyStatus ? (
                <img
                  src="/assets/icons/copy.svg"
                  width={15}
                  height={21}
                  alt="copy"
                  title={hash}
                />
              ) : (
                <img
                  src="/assets/icons/check.svg"
                  width={15}
                  height={21}
                  alt="copy"
                />
              )}
            </CopyToClipboard>
          </div>
        )}
        <span className="low-w email" title={email}>
          {email}
        </span>
      </div>
      <Tooltip
        id="myTooltip"
        type="light"
        offset={12}
        place="top-center"
        className="tooltip"
      />
    </div>
  );
};

export default CompanyWithSubs;
