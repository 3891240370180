import React from "react";
import "./styles.scss";
import classNames from "classnames";
import useStore from "../../store/store";
import roleStore from "../../store/role-store";

const TopBar = ({ children }) => {
  const { sidebarIsOpen, toggleSideBar } = useStore();
  const { userRole } = roleStore();

  return (
    <div className={classNames("top-bar", { 
      "is-open": sidebarIsOpen,
      "topbar-admin": userRole !== "SUBSCRIBER",
        "topbar-subscriber": userRole === "SUBSCRIBER"
       })}>
      { userRole !== "SUBSCRIBER" ? 
      <img
        className="toggle-btn"
        src="/assets/icons/Vector.svg"
        alt="vector"
        onClick={toggleSideBar}
      />  : ""  }

      {children}
    </div>
  );
};

export default TopBar;
