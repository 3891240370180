import React from "react";
import "./styles.scss";

const EmbedMessage = ({
  message,
  icon = "/assets/icons/IconRed.svg",
  icon2 = "/assets/icons/greenIcon.svg",
  icon3 = "/assets/icons/iconTick.svg",
  messageTypeSuccess = false,
  showCross = false,
  toggle,
}) => {
  const getBackgroundColor = () => {
    if (messageTypeSuccess) {
      return { backgroundColor: "#66CB9F" };
    } else if (messageTypeSuccess !== null) {
      return { backgroundColor: "#f16063" };
    } else {
      return {
        backgroundColor: "#fff",
        boxShadow:
          "0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)",
        borderRadius: "16px",
        position: "relative",
      };
    }
  };

  const iconSrc = messageTypeSuccess
    ? icon2
    : messageTypeSuccess !== null
    ? icon
    : icon3;

  return (
    <div
      className="embed-message flex"
      style={getBackgroundColor()}
    >
      <img
        src={ iconSrc }
        alt="embed-icon"
      />
      <span
        style={{
          color: messageTypeSuccess == null ? "#27272E" : "",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          paddingRight: "2em",
        }}
        className="low"
      >
        {message}
      </span>
      {showCross && (
        <div className="cross-img">
          <img
            src="/assets/icons/modalCross.svg"
            alt="modal-cross"
            onClick={toggle}
          />
        </div>
      )}
    </div>
  );
};

export default EmbedMessage;
