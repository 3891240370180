import React from "react";
import "./styles.scss";
import {
  getFirstWordUpperOFSetence,
  makeFirtLetterCapital,
} from "../../utilities/functions/common";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const Status = ({
  status,
  explicitType,
  isComingSoon,
  rejectedReason,
  failedReason,
}) => {
  const sStatus = status?.toLowerCase();
  return (
    <div className={`status-bage ${sStatus} ${explicitType} ${isComingSoon}`}>
      <div>{getFirstWordUpperOFSetence(sStatus)}</div>
      <div>
        {(status === "REJECTED" || status === "FAILED") && (
          <div
            data-tooltip-id="my-tooltip"
            data-tooltip-content={makeFirtLetterCapital(
              rejectedReason || failedReason
            )}
          >
            {status === "REJECTED" && (
              <img
                src="/assets/icons/infoIcon.svg"
                alt="info icon"
                style={{ cursor: "text" }}
              />
            )}
            {status === "FAILED" && (
              <img
                src="/assets/icons/infoIcons.svg"
                alt="info icon"
                style={{ cursor: "text" }}
              />
            )}
          </div>
        )}
      </div>
      <Tooltip
        id="my-tooltip"
        type="light"
        offset={12}
        place="top-center"
        className="tooltip"
      />
    </div>
  );
};

export default Status;
