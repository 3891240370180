import React from "react";
import { useTable } from "react-table";
import { isEmpty } from "lodash";
import "./styles.scss";
import Pagination from "../Pagination";
import RSelect from "../RSelect";
import RSearch from "../RSearch";
import { getRecordsNumber } from "../../utilities/functions/common";
import EmptyListing from "../EmptyListing";
// import { optionsForOnboard } from "../../utilities/static";
import DateRange from "../DateRange";

function TableView({
  columns,
  data = [],
  className,
  currentLenghtOfRecord,
  forTransactions,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table
      {...getTableProps()}
      className={`${className} ${forTransactions ? "transaction-table" : ""}`}
      data-testid="table-test"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="m-tr">
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{ font: "bold" }}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {currentLenghtOfRecord > 0 && (
        <tbody {...getTableBodyProps()} className="m-body">
          {rows?.length > 0 &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      )}
    </table>
  );
}

function Table({
  width,
  headers,
  data,
  page = 1,
  count = 0,
  limit = 10,
  searchValue,
  oboardFilter,
  oboardFilter1,
  currentPage = 0,
  options1 = [],
  options2 = [],
  pageCount = 1,
  onPageChange,
  selectTwoType,
  optionValue1,
  optionValue2,
  optionValue3,
  onSearchChange,
  handleSaveFilterVal,
  isOnBoardFilter = false,
  forRewardAccess = false,
  currentLenghtOfRecord = 0,
  enablePagination = false,
  forTransactions = false,
  isSortByFilter = false,
  isTransactionFilter = false,
  handleDateChange,
  isDateRange = false,
  companysOnboard,
  mediaAgencies,
  subscribers,
}) {
  const columns = [...headers];
  return (
    <div className="table-div">
      <div className="centered-div" data-testid="Table-View-test">
        {enablePagination && !forTransactions && (
          <div
            className="ml-24 mr-24"
            style={{
              justifyContent: isTransactionFilter
                ? "flex-end"
                : "space-between",
              display: "flex",
              alignContent: "center",
              margin: "-14px 24px",
            }}
          >
            {/* {isDateRange ? (
              <DateRange onDateChange={handleDateChange} sLabel="Date" />
            ) : !isTransactionFilter ? (
              <RSearch value={searchValue} onChange={onSearchChange} />
            ) : (
              ""
            )} */}

            {/* (isDateRange && !isTransactionFilter) */}

            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {!isTransactionFilter &&
                (companysOnboard ? (
                  <RSearch
                    value={searchValue}
                    onChange={onSearchChange}
                    width={100}
                  />
                ) : (
                  <>
                    <RSearch
                      value={searchValue}
                      onChange={onSearchChange}
                      width={100}
                    />
                    {isDateRange && (
                      <DateRange
                        onDateChange={handleDateChange}
                        sLabel="Date"
                      />
                    )}
                  </>
                ))}
            </div>

            {/* className="flex r-selects-row" */}

            <div className="flex tableView">
              {companysOnboard && (
                <>
                  <RSelect
                    sLabel="Company"
                    options={mediaAgencies}
                    value={oboardFilter}
                    onChange={handleSaveFilterVal}
                    width={110}
                    menuWidth={150}
                    placeholder="All"
                  />
                  <RSelect
                    sLabel="Subscriber"
                    options={subscribers}
                    value={oboardFilter1}
                    onChange={handleSaveFilterVal}
                    width={110}
                    menuWidth={150}
                    placeholder="All"
                  />
                </>
              )}

              {!isSortByFilter && !isTransactionFilter && (
                <RSelect
                  width={companysOnboard ? 95 : 148}
                  sLabel={selectTwoType}
                  options={options2}
                  value={optionValue2}
                  menuWidth={companysOnboard && 140}
                  onChange={handleSaveFilterVal}
                />
              )}

              <RSelect
                sLabel="Show"
                options={options1}
                width={companysOnboard ? 95 : 148}
                value={optionValue1}
                onChange={handleSaveFilterVal}
              />
            </div>
          </div>
        )}
        <TableView
          columns={columns}
          data={!isEmpty(data) ? data : []}
          className="centered-div-table"
          currentLenghtOfRecord={currentLenghtOfRecord}
          forTransactions={forTransactions}
        />
        {currentLenghtOfRecord <= 0 && <EmptyListing />}
        {currentLenghtOfRecord > 0 && !forRewardAccess && (
          <div className="flex space-between mt-24 mr-32 pagination-rw">
            <div
              className="low ml-24"
              style={{ lineHeight: "23px", color: "#7A7A9D" }}
            >
              Showing {getRecordsNumber(limit, page, currentLenghtOfRecord)}{" "}
              items out of {count} results found
            </div>

            {enablePagination && !isEmpty(data) && (
              <div className="pagination-div" id="pagination">
                <Pagination
                  onPageChange={onPageChange}
                  pageCount={pageCount}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Table;
