import React, { useRef } from "react";
import BoxHoc from "./../../hoc/BoxHoc";
import { Col, Row } from "reactstrap";
import RForm from "../../components/RForm";
import { changePasswordFields } from "../../utilities/static";
import RButton from "../../components/RButton";
import TopBar from "../../hoc/TopBar";
import "./styles.scss";
import useStore from "../../store/store";
import { changePassword } from "../../apis/apis";
import { getError } from "../../utilities/functions/common";
import Loader from "../../components/Loader";
import roleStore from "../../store/role-store";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { loading, setLoading } = useStore();
  const { loggedUser } = roleStore();
  let passRef = useRef(null);
  const handleSubmit = () => {
    passRef.current();
  };

  const handleChangePassword = (payload) => {
    changePassword(payload, loggedUser?.u_id)
      .then((response) => {
        toast.success('Your password has been updated.')
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(getError(err))
      });
  };

  const submit = (data) => {
    setLoading(true);
    const payload = {
      email: loggedUser?.email,
      currentPassword: data?.currentPassword,
      password: data?.newPassword,
      confirmPassword: data?.confirmPassword,
    };
    handleChangePassword(payload);
  };

  return (
    <div className="change-password-ctn">
      <TopBar>
        <div>
          <h1>Account</h1>
        </div>
      </TopBar>

      <div className="inner-ctn">
        <BoxHoc>
          <Row className="change-pass-inner">
            <Col lg={12}>
              <div className="mediumUpper">Change Password</div>
              <RForm
                formList={changePasswordFields}
                ref={passRef}
                handleSubmit={submit}
              />
              <div className="save-btn">
                <RButton
                  type="submit"
                  btnText="Save"
                  styles={{ marginTop: "30px" }}
                  onClick={handleSubmit}
                />
              </div>
            </Col>
          </Row>
        </BoxHoc>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default ChangePassword;
