import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import TopBar from "../../hoc/TopBar";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import { uniqWith, isEqual, isEmpty } from "lodash";
import GoldiesBox from "../../components/GoldiesBox";
import { Col, Row } from "reactstrap";
import {
  fetchWalletDetail,
  createWithdraw,
  fetchWalletAccountListing,
  addWalletAccount,
  getWalletDetailById,
  deleteWalletAccountById,
  fecthSettings,
  fetchAccountNames,
} from "../../apis/apis";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import {
  parseWalletResponse,
  parseSingleWalletDetail,
} from "../../utilities/functions/parsers";
import RButton from "../../components/RButton";
import CommonModal from "../../components/CommonModal";
import ConfirmationPopup from "../../components/MediaStatus";
import WithdrawGoldies from "../../components/WithdrawGoldies";
import EmbedMessage from "../../components/EmbedMessage";
import { Nav, NavItem, NavLink } from "reactstrap";
import TableBox from "../../components/TableBox";
import { headersForWalletAccounts } from "../../utilities/static";
import roleStore from "../../store/role-store";
import { useNavigate } from "react-router-dom";
import {
  getError,
  throughError,
  makeFirtLetterCapital,
} from "../../utilities/functions/common";
import {
  parsingWalletListing,
  parseSettings,
  parseApprovalTime,
} from "../../utilities/functions/parsers";
import {
  makeDataForAccounts,
  showFilterOptionsForMedia,
} from "../../utilities/static";
import "./styles.scss";
import AddWalletAcount from "../../components/AddWalletAccount";
import CompanyWithSubs from "../../components/CompanyWithSubs";
import ReSizeWindow from "../../customHooks/ReSizeWindow";
import TransactionAccount from "../../components/TransactionDetail";
import { toast } from "react-toastify";

const Wallet = () => {
  let formRef = useRef();
  const dropdownRef = useRef(null);
  const { subscriberId, subscriberName, crumbCheck, companyId, compName } =
    useParams();

  const { loading, setLoading } = useStore();
  const { loggedUser, userRole } = roleStore();
  const { isMobileScreen } = ReSizeWindow();
  const [data, setData] = useState({});
  const [openDropdown, setOpenDropdown] = useState(false);
  const [walletDetail, setDetail] = useState({});
  const [walletData, setCurrentWallet] = useState({});
  const [withdrawDetail, setCurrentWithdraw] = useState({});
  const [isOpen, setModal] = useState(false);
  const [isConfirmOpen, setConfirmModal] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("earnings");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isEmbedMessage1, setEmbedMessage1] = useState(<></>);
  const [currentPage, setCurrentPage] = useState(0);
  const [walletListing, setWalletListing] = useState([]);
  const [mediaId, setMediaId] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [statusPopup, setStatusPopup] = useState(false);
  const [showFilterWallet, setShowFilterWallet] = useState(
    showFilterOptionsForMedia[0]
  );
  const [walletName, setWalletName] = useState([]);
  const [walletUpdate, setWalletUpdate] = useState("");
  const [timePeriod, setTimePeriod] = useState("");

  const getTabCursor = (tabName) => {
    return selectedTab !== tabName ? "pointer" : "default";
  };
  let navigate = useNavigate();
  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getWalletListing(loPage + +data?.selected);

    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getWalletListing = (show = 10, page = 1) => {
    const userId = loggedUser._id;
    const email = encodeURIComponent(loggedUser?.email);
    const params = { show, page, userId, email };
    setLoading(true);
    fetchWalletAccountListing(params)
      .then((response) => {
        const { count, totalPages, page, userCryptoAccounts, limit } =
          response?.data?.data;
          
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });
        let pList = parsingWalletListing(userCryptoAccounts);
        setWalletListing(makeDataForAccounts(pList, handleActionType));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const getWalletIdDetail = (id) => {
    getWalletDetailById(id)
      .then((resposne) => {
        setCurrentWallet(parseSingleWalletDetail(resposne?.data?.data));
        setLoading(false);
        toggle(id, false);
        setEditPopup(true);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const accountNames = () => {
    fetchAccountNames()
      .then((resposne) => {
        setLoading(false);
        const pList = resposne?.data?.data;
        setWalletName(pList);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleActionType = (type, mediaId) => {
    setLoading(true);
    setMediaId(mediaId ?? null);
    if (type === "edit") {
      getWalletIdDetail(mediaId);
    } else if (type === "delete") {
      toggleHandleStatus();
      setMediaId(mediaId);
    }
    setLoading(false);
  };

  const toggleHandleStatus = () => {
    setStatusPopup((prev) => !prev);
  };

  const crumbs = {
    admin: [
      {
        to: "/companies",
        txt: "Media Companies",
        active: false,
      },
      {
        to: `/company-detail/${companyId}/${compName}`,
        txt: compName,
        active: false,
      },
      {
        to: "",
        txt: `${subscriberName}'s Wallet`,
        active: false,
      },
    ],
    media: [
      {
        to: "/subscribers-list",
        txt: "Subscribers",
        active: false,
      },
      {
        to: "",
        txt: `${subscriberName}'s Wallet`,
        active: false,
      },
    ],
  };

  const confirmCreateWithdrawal = (data) => {
    setConfirmModal(true);
    setCurrentWithdraw(data);
  };

  const handleCreateWithdraw = () => {
    setLoading(true);
    const payload = {
      userId: loggedUser?._id,
      firstName: loggedUser?.firstName,
      lastName: loggedUser?.lastName,
      email: loggedUser?.email,
      transactionId: "213",
      goldiesAmount: Number(withdrawDetail.withdrawAmount),
      gldyConvertedAmount:
        Number(withdrawDetail.withdrawAmount) /
        data?.goldiesLimit?.gldyEquivalentGoldiesAmount,
      toAccountName: withdrawDetail.walletName.label,
      toAccount: withdrawDetail.walletAddress,
      fromAccount: "123",
    };
    createWithdraw(payload)
      .then((response) => {
        setLoading(false);
        setCurrentWithdraw({});
        formRef?.current?.makefieldsClear();
        setConfirmModal(false);
        setModal(false);
        setShowSuccess(true);
        getWalletsDetail();
        setWalletUpdate(!walletUpdate);
      })
      .catch((err) => {
        throughError(err, navigate);
        setLoading(false);
        setConfirmModal(false);
        setEmbedMessage1(<EmbedMessage message={getError(err)} />);
      });
  };

  const LocalPaginationInfoAccounts = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: walletListing?.length,
  };

  useEffect(() => {
    getWalletsDetail();
    // eslint-disable-next-line
  }, [subscriberId, loggedUser._id]);

  useEffect(() => {
    if (selectedTab === "accounts") {
      getWalletListing();
    }
    if (userRole === "SUBSCRIBER") {
      if (selectedTab === "transactions" || "earnings") {
        accountNames();
      }
    }

    // eslint-disable-next-line
  }, [selectedTab, setSelectedTab]);

  const getWalletsDetail = () => {
    setLoading(true);
    const loggedId = loggedUser._id;
    let subscribid = loggedUser.role !== "SUBSCRIBER" ? subscriberId : loggedId;
    fetchWalletDetail(subscribid)
      .then((resp) => {
        const walletData = parseWalletResponse(resp?.data?.data);
        setDetail(walletData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getSettings = () => {
    fecthSettings()
      .then((response) => {
        const pSettings = parseSettings(response?.data?.data?.data);
        setData(pSettings);
        setTimePeriod(
          parseApprovalTime(pSettings?.walletLimit?.withdrawApprovalTime)
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const getWalletFormData = (data, makeToggleoAndToast = true) => {
    const walletFormData = {
      walletName: data.walletsName,
      walletAddress: data.addWalletAddress,
    };

    handleAddWalletAccount(walletFormData, makeToggleoAndToast);
  };

  const handleAddWalletAccount = (walletFormData, makeToggle) => {
    const payload = {
      ...walletFormData,
      email: loggedUser.email,
      userId: loggedUser._id,
    };
    setLoading(true);
    addWalletAccount(payload, mediaId)
      .then((response) => {
        getWalletListing();
        setWalletModal(false)
        setCurrentWallet({});
        toast.success(
          !editPopup
            ? "Wallet Account has been added."
            : "Your changes have been saved."
        );
        // makeToggle &&
        //   setEmbedMessage1(
        //     <EmbedMessage
        //       messageTypeSuccess={true}
        //       icon="/assets/icons/greenIcon.svg"
        //       message={
        //         !editPopup
        //           ? "Wallet Account has been added."
        //           : "Your changes have been saved."
        //       }
        //     />
        //   );
        setLoading(false);
        formRef?.current?.makefieldsClear();
      })
      .catch((err) => {
        toast.error(getError(err))
        // setEmbedMessage1(<EmbedMessage message={getError(err)} />);
        setLoading(false);
      });
  };

  const handleConfirmModal = () => {
    setEmbedMessage1(<></>);
    setModal(true);
    getSettings();
  };

  const toggle = (id, makeIdNull = true) => {
    setEmbedMessage1(<></>);
    setMediaId(makeIdNull ? null : id);
    setEditPopup(!makeIdNull);
    setWalletModal((prev) => !prev);
    if (makeIdNull) {
      setCurrentWallet({});
    }
  };

  const getDeleteWalletById = (id) => {
    deleteWalletAccountById(id)
      .then((resposne) => {
        setLoading(false);
        getWalletListing();
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleDeleteWallet = () => {
    if (mediaId !== null && mediaId !== undefined && mediaId !== "") {
      getDeleteWalletById(mediaId);
      toggleHandleStatus();
    }
  };

  const getlogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleSaveFilterValue = (value, type) => {
    setShowFilterWallet(value);
    getWalletListing(value?.value);
  };

  const renderTableBox = () => {
    if (selectedTab === "transactions") {
      return (
        <TransactionAccount
          enablePagination={true}
          walletUpdate={walletUpdate}
        />
      );
    } else if (selectedTab === "accounts") {
      return (
        <TableBox
          enablePagination={true}
          onPageChange={onPageChange}
          headers={headersForWalletAccounts}
          paginationInfo={LocalPaginationInfoAccounts}
          data={walletListing}
          optionValue1={showFilterWallet}
          options1={showFilterOptionsForMedia}
          isSortByFilter={true}
          isTransactionFilter={true}
          handleSaveFilterVal={handleSaveFilterValue}
        />
      );
    } else if (selectedTab === "earnings") {
      return (
        <>
          {!isEmpty(walletDetail)
            ? walletDetail.map((goldie) => (
                <Col
                  lg={3}
                  key={
                    goldie.count + goldie?.goldiesType + goldie?.goldiesSource
                  }
                  className="goldie-col"
                >
                  <GoldiesBox {...goldie} showSubCount={false} />
                </Col>
              ))
            : !isEmpty(loading) &&
              loading === false && <div>No Wallet Found!</div>}
        </>
      );
    }
  };
  return (
    <div className="subscriber-wallet">
      <TopBar>
        {userRole !== "SUBSCRIBER" ? (
          <div className="">
            <h1>
              {" "}
              {JSON.parse(crumbCheck) ? "Media Companies" : "Subscribers"}
            </h1>
            {!isEmpty(walletDetail) && (
              <CustomBreadCrumb
                crumbs={uniqWith(
                  crumbs[JSON.parse(crumbCheck) ? "admin" : "media"],
                  isEqual
                )}
              />
            )}{" "}
          </div>
        ) : (
          <div className="topbar-content">
            <img
              src="/assets/icons/logo.png"
              alt="logo"
              style={{ cursor: "pointer", width: '12rem' }}
              onClick={() => navigate("/")}
            />
            <div className="dropdown">
              <div
                className={
                  isMobileScreen
                    ? "top-dropdown mblWidth"
                    : "top-dropdown dtpWidth"
                }
                onClick={() => {
                  setOpenDropdown((prevOpenDropdown) => !prevOpenDropdown);
                }}
                ref={dropdownRef}
              >
                {isMobileScreen ? (
                  <CompanyWithSubs
                    isEmailUnder={false}
                    isAgencyShow={false}
                    avatar={loggedUser.subscriberImage}
                    navigateTo={false}
                  />
                ) : (
                  <CompanyWithSubs
                    isEmailUnder={false}
                    name={
                      makeFirtLetterCapital(loggedUser.firstName) +
                      " " +
                      makeFirtLetterCapital(loggedUser.lastName)
                    }
                    isAgencyShow={false}
                    email={loggedUser?.email}
                    avatar={loggedUser.subscriberImage}
                    navigateTo={false}
                  ></CompanyWithSubs>
                )}
                <div>
                  {openDropdown ? (
                    <img
                      src="/assets/icons/upPath.svg"
                      alt="arrow"
                      className="pathIcon"
                    />
                  ) : (
                    <img
                      src="/assets/icons/downPath.svg"
                      alt="arrow"
                      className="pathIcon"
                    />
                  )}
                </div>
              </div>

              {openDropdown && (
                <div
                  className={
                    isMobileScreen
                      ? "dropdown-val mblWidth"
                      : "dropdown-val dtpWidth"
                  }
                >
                  <div className="dropdown-value" onClick={() => getlogout()}>
                    Logout
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </TopBar>

      <div
        className={userRole !== "SUBSCRIBER" ? "inner-ctn" : "inner-subscriber"}
      >
        {showSuccess && (
          <EmbedMessage
            messageTypeSuccess={null}
            message={`Your request for withdrawal has been sent successfully. You have to wait ${timePeriod} for approval.`}
            showCross={true}
            toggle={() => {
              setShowSuccess(false);
            }}
          />
        )}
        <div className="mt-3">
          <h1>Wallet</h1>
          <div className="button-row">
            <Nav className="nav-wallet mt-3">
              <NavItem>
                <NavLink
                  className="wallet-tabs"
                  style={{ cursor: getTabCursor("earnings") }}
                  onClick={() => setSelectedTab("earnings")}
                  active={selectedTab === "earnings"}
                >
                  My Earnings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="wallet-tabs "
                  style={{ cursor: getTabCursor("transactions") }}
                  onClick={() => setSelectedTab("transactions")}
                  active={selectedTab === "transactions"}
                >
                  Transaction
                </NavLink>
              </NavItem>
              {userRole === "SUBSCRIBER" && (
                <NavItem>
                  <NavLink
                    className="wallet-tabs"
                    style={{ cursor: getTabCursor("accounts") }}
                    onClick={() => setSelectedTab("accounts")}
                    active={selectedTab === "accounts"}
                  >
                    Wallet Accounts
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            {userRole === "SUBSCRIBER" && (
              <div className="withdrawbutton">
                <RButton
                  type="submit"
                  isSpinner={true}
                  btnText={
                    selectedTab !== "accounts" ? "Withdraw" : "Add Account"
                  }
                  onClick={() =>
                    selectedTab !== "accounts"
                      ? handleConfirmModal()
                      : toggle(true)
                  }
                  className="submit-button"
                />
              </div>
            )}
          </div>
        </div>

        <Row>{renderTableBox()}</Row>
      </div>

      <CommonModal
        isOpen={isOpen}
        toggle={() => setModal(false)}
        className="for-media-popup"
        showCross={true}
      >
        <WithdrawGoldies
          formRef={formRef}
          setConfirmModal={(newVal) => setConfirmModal(newVal)}
          walletDetail={walletDetail}
          data={data}
          walletName={walletName}
          createWithdrawal={confirmCreateWithdrawal}
          isEmbedMessage={isEmbedMessage1}
        />
      </CommonModal>
      <CommonModal
        isOpen={isConfirmOpen}
        toggle={() => setConfirmModal(false)}
        showCross={false}
      >
        <ConfirmationPopup
          onClickbtn1={handleCreateWithdraw}
          title="You want to create the withdraw request"
          onClickbtn2={() => setConfirmModal(false)}
          pText="Please note that request will take several days to go through several process"
        />
      </CommonModal>

      <CommonModal
        isOpen={walletModal}
        toggle={() => toggle(false)}
        className="for-media-popup"
      >
        <AddWalletAcount
          formRef={formRef}
          editPopup={editPopup}
          walletData={walletData}
          isEmbedMessage={isEmbedMessage1}
          getWalletFormData={getWalletFormData}
        />
      </CommonModal>

      <CommonModal
        isOpen={statusPopup}
        toggle={toggleHandleStatus}
        showCross={false}
      >
        <ConfirmationPopup
          onClickbtn2={toggleHandleStatus}
          onClickbtn1={handleDeleteWallet}
          title="you want to delete this wallet account"
          pText="Deleting your account permanently erases data and assets. Backup required"
        />
      </CommonModal>

      <Loader loading={loading} />
    </div>
  );
};

export default Wallet;
