import React, { useRef, useState } from "react";
import RForm from "../../components/RForm";
import {
  accountFieldsForAdmin,
  accountFieldsForAgency,
} from "../../utilities/static";
import TopBar from "../../hoc/TopBar";
import RButton from "../../components/RButton";
import { useNavigate } from "react-router-dom";
import { accountUpdate } from "../../apis/apis";
import useStore from "../../store/store";
import { throughError } from "../../utilities/functions/common";
import Loader from "../../components/Loader";
import roleStore from "../../store/role-store";
import "./styles.scss";
import ImgUploader from "../../components/ImgUploader";
import { toast } from "react-toastify";

const Account = () => {
  const {
    loading,
    setLoading,
    setImgError,
    imgError,
  } = useStore();
  const { setLoggedUser, loggedUser, isAdmin } = roleStore();
  const [imgKey, setImgKey] = useState(
    isAdmin ? loggedUser?.userImage : loggedUser?.agencyImage
  );
  let formRef = useRef(null);
  let navigate = useNavigate();
  const handleSubmit = () => {
    formRef.current();
  };

  const updateInfo = (payload) => {
    accountUpdate(payload, isAdmin, loggedUser?._id)
      .then((response) => {
        setLoggedUser({
          ...response?.data?.data,
          role: isAdmin ? "SUPER_ADMIN" : "MEDIA_AGENCY",
          u_id: !isAdmin ? loggedUser?.u_id : response?.data?.data?._id
        });
        setLoading(false);
        // setCurrentPopupType("successfully");
        toast.success("Account has been updated.");
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const submit = (data) => {
    if (!imgKey || imgKey === "string") {
      setImgError("Image is required.");
    } else {
      setLoading(true);
      const common = {
        email: loggedUser?.email,
        userImage: imgKey,
        userImagePath: imgKey,
      };
      const payload = {
        ...common,
        firstName: data?.firstName,
        lastName: data?.lastName,
      };

      const payloadForAgency = {
        ...common,
        representativeName: data?.representativeName,
        mediaCompany: loggedUser?.mediaCompany,
      };

      updateInfo(isAdmin ? payload : payloadForAgency);
    }
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const getKeyOfImg = (key) => {
    setImgKey(key);
  };

  return (
    <div className="account-container">
      <TopBar>
        <div className="account-barContent">
          <h1>Account</h1>
        </div>
      </TopBar>

      <div className="inner-ctn">
        <ImgUploader getKeyOfImg={getKeyOfImg} imgKey={imgKey} />
        {imgError && <div className="error">{imgError}</div>}
        <div className="mediumUpper mt-32">Account Informations</div>
        <RForm
          formList={
            isAdmin
              ? accountFieldsForAdmin(loggedUser)
              : accountFieldsForAgency(loggedUser)
          }
          handleSubmit={submit}
          ref={formRef}
          handlePassword={handleChangePassword}
        />

        <div className="save-changes-btn">
          <RButton
            type="submit"
            btnText="Save changes"
            styles={{ marginTop: "30px" }}
            onClick={handleSubmit}
          />
        </div>
      </div>

      <Loader loading={loading} />
    </div>
  );
};

export default Account;
