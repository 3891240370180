import React, { Fragment } from "react";
import RForm from "../../components/RForm";
import { forgetPasswordFields } from "../../utilities/static";
import RButton from "../../components/RButton";
import EmbedMessage from "../../components/EmbedMessage";

const ForgetPassword = ({
  formRef,
  foegetHandle,
  handleSubmit,
  recoveryEmail,
  isEmailSent = false,
  handleForgetPassword,
}) => {
  return (
    <div className="sign-in-box">
      <div className="m-logo">
        <img src="/assets/icons/logo.png" alt="logo-log" />
      </div>

      <h1 className="mb-8 mt-32">Forgot Password</h1>
      <div className="lowMedium mb-16">
        No worries, we'll send you reset instructions.
      </div>

      {!isEmailSent && (
        <Fragment>
          <RForm
            ref={formRef}
            handleSubmit={foegetHandle}
            formList={forgetPasswordFields}
          />

          <RButton
            type="submit"
            formtxt="c-form"
            btnText="Confirm"
            styles={{ marginTop: "40px" }}
            onClick={handleSubmit}
          />
          <div
            className="cursor small back-login mt-40"
            onClick={handleForgetPassword}
          >
            Back to login
          </div>
        </Fragment>
      )}

      {isEmailSent && (
        <div className="recovry-title">
          <EmbedMessage
            message="Reset request sent to your email."
            messageTypeSuccess={true}
          />
          <div className="email-title mt-40">E-mail</div>
          <div className="lowMedium mt-8">
            We sent a recovery link to you at
            <br />
            <span>{recoveryEmail}</span>
          </div>

          <div className="return-txt mt-64">
            <span onClick={handleForgetPassword}>Return to login . </span>
            <span onClick={() => foegetHandle({ email: recoveryEmail })}>
              Resend recovery link
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;
