import React from "react";
import RForm from "../RForm";
import { addWalletAccountFields } from "../../utilities/static";
import RButton from "../RButton";
import "./styles.scss";


const AddWalletAcount = ({
  formRef,
  editPopup,
  isEmbedMessage,
  walletData = {},
  getWalletFormData,
}) => {

  const handleSubmit = () => {
    formRef?.current?.check();
  };

  return (
    <div className="add-media-company">
      <h1>{!editPopup ? "Add Wallet Account" : "Edit Wallet Account"}</h1>
      <div className="lowMedium" style={{ color: "#27272E" }}>
        {!editPopup
          ? "Add new Wallet account"
          : "Make changes to existing wallet account"}
      </div>
      {/* {isEmbedMessage} */}
      <RForm
        ref={formRef}
        formList={addWalletAccountFields(walletData)}
        isFormForMedia={true}
        handleSubmit={getWalletFormData}
      />
      <RButton
        type="submit"
        isSpinner={true}
        onClick={handleSubmit}
        styles={{ marginTop: "39px" }}
        btnText={!editPopup ? "Add Account" : "Save Changes"}
      />
    </div>
  );
};

export default AddWalletAcount;
