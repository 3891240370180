import React from "react";
import RForm from "../RForm";
import RButton from "../RButton";
import { withdrawGoldiesFields } from "../../utilities/static";
import "./styles.scss";
import { Card } from "reactstrap";

const WithdrawGoldies = ({
  formRef,
  walletDetail,
  createWithdrawal,
  isEmbedMessage,
  data,
  walletName,
}) => {
  const handleSubmit = () => {
    formRef?.current?.check();
  };

  return (
    <div className="add-media-company">
      <h1>Withdraw Goldies</h1>
      <div className="lowMedium" style={{ color: "#27272E" }}>
        Withdraw your Goldies by using Account Key
      </div>
      {isEmbedMessage}
      <Card className="withdraw-card">
        <div className="d-flex justify-content-between m-3">
          {walletDetail &&
            walletDetail.length &&
            walletDetail
              .filter(
                (d) => d.goldiesType.toLowerCase() === "total earned goldies"
              )
              .map((x) => (
                <span key={x.count}>
                  <h1 className="text-left">{x.count}</h1>
                  <sub className="card-desc">{x.goldiesType}</sub>
                </span>
              ))}
          <span>
            <img
              src="/assets/iconsForTemplate/earnedGoldie.png"
              alt="earned-goldie"
              width={46}
            />
          </span>
        </div>
        <div className="d-flex justify-content-between mx-3">
          <span>
            <sub className="card-desc">Minimum Withdraw</sub>
          </span>
          <span>
            <span className="card-desc">{ data?.walletLimit?.minWithdraw }</span>
          </span>
        </div>
        <div className="d-flex justify-content-between mx-3 mb-4">
          <span>
            <sub className="card-desc">Maximum Withdraw</sub>
          </span>
          <span>
            <span className="card-desc">{ data?.walletLimit?.maxWithdraw }</span>
          </span>
        </div>
      </Card>
      <RForm
        ref={formRef}
        formList={withdrawGoldiesFields(data?.walletLimit?.minWithdraw, data?.walletLimit?.maxWithdraw, walletName, data?.goldiesLimit?.gldyEquivalentGoldiesAmount)}
        goldiesInGLDY={data?.goldiesLimit?.gldyEquivalentGoldiesAmount}
        isFormForMedia={true}
        handleSubmit={createWithdrawal}
      />

      <RButton
        type="submit"
        onClick={handleSubmit}
        disabled={false}
        styles={{ marginTop: "39px" }}
        btnText={"Withdraw"}
      />
    </div>
  );
};

export default WithdrawGoldies;
