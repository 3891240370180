import React from "react";
import moment from "moment";
import "./style.scss";
import { DatePicker, ConfigProvider } from "antd";

const { RangePicker } = DatePicker;

const DateRange = ({ onDateChange, sLabel }) => {

  const theme = {
    DatePicker: {
      activeBorderColor: "#FA1C3C",
      cellHoverBg: "#FA1C3C",
      addonBg: "#FA1C3C",
      cellActiveWithRangeBg: "#FFE6E4",
      activeShadow: "none",
      hoverBorderColor: "#FA1C3C",
    },
  };


  const handleDateChange = (value) => {
    if (Array.isArray(value) && value.length > 0) {
      const formattedDates = value.map((date) =>
        moment(date.$d).format("YYYY-MM-DD")
      );
      const endDate = moment(value[1].$d).add(1, "day").toDate();
      onDateChange({
        startDate: formattedDates[0],
        endDate: moment(endDate).format("YYYY-MM-DD")
      });
    } else {
      onDateChange({ startDate: null, endDate: null });
    }
  };

  return (
    <div className="daterange flex" style={{ minHeight: "46px" }}>
      {sLabel && <div className="s-label ml-16">{sLabel}</div>}
      <ConfigProvider theme={{ components: { ...theme } }}>
        <RangePicker
          style={{ backgroundColor: "white" }}
          onChange={handleDateChange}
          placeholder={["2024-02-12", "2025-05-24"]}
        />
      </ConfigProvider>
    </div>
  );
};

export default DateRange;
