import React, { useEffect, useState, useRef } from "react";
import TableBox from "../TableBox";
import useStore from "../../store/store";
import roleStore from "../../store/role-store";
import Loader from "../Loader";
import { throughError } from "../../utilities/functions/common";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CommonModal from "../CommonModal";
import ConfirmationPopup from "../MediaStatus";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import {
  fetchTransactionListing,
  getTrasactionById,
  updateTransactionById,
} from "../../apis/apis";
import {
  headersTransactionsForAdmin,
  showFilterOptionsForMedia,
  headersForTransactions,
  dataForTransactionAdmin,
  showFilterOptionsForListing,
  transactionStatus,
} from "../../utilities/static";

import { parsingTransactionListingForAdmin } from "../../utilities/functions/parsers";

const TransactionAccount = ({
  enablePagination = true,
  walletUpdate,
  listing,
}) => {
  const { subscriberEmail, companyId } = useParams();
  let formRef = useRef();
  const { loading, setLoading } = useStore();
  const { isAdmin, userRole, loggedUser } = roleStore();
  let navigate = useNavigate();
  const [transactionListing, setTransactionListing] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionDetail, setTransactionDetail] = useState({});
  const [isDeleteOpen, setDeleteModal] = useState(false);
  const [isApproveOpen, setApproveModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [deleteType, setDeleteType] = useState({});
  const [approveType, setApprovalType] = useState({});
  const [showFilter, setShowFilter] = useState(
    listing ? showFilterOptionsForListing[0] : showFilterOptionsForMedia[0]
  );
  const [typeByFilter, setTypeByFilter] = useState(transactionStatus[0]);
  const [selectedDates, setSelectedDates] = useState("");

  useEffect(() => {
    getTransactionListing(showFilter?.value);
    // eslint-disable-next-line
  }, [walletUpdate]);

  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getTransactionListing(
      showFilter?.value,
      typeByFilter?.value,
      selectedDates?.startDate,
      selectedDates?.endDate,
      searchValue,
      loPage + +data?.selected
    );
    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  const LocalPaginationInfo = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: transactionListing?.length,
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    getTransactionListing(
      showFilter?.value,
      typeByFilter?.value,
      selectedDates?.startDate,
      selectedDates?.endDate,
      e.target.value
    );
  };

  const handleSaveFilterVal = (value, type) => {
    setCurrentPage(0);
    if (type === "Show") {
      setShowFilter(value);
      getTransactionListing(
        value?.value,
        typeByFilter?.value,
        selectedDates?.startDate,
        selectedDates?.endDate,
        searchValue,
      );
    } else {
      setTypeByFilter(value);
      getTransactionListing(
        showFilter?.value,
        value?.value,
        selectedDates?.startDate,
        selectedDates?.endDate,
        searchValue,
      );
    }
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    getTransactionListing(
      showFilter?.value,
      typeByFilter?.value,
      dates?.startDate,
      dates?.endDate,
      searchValue,
    );
  };

  const updateTransactionDetail = (payload, id) => {
    updateTransactionById(payload, id)
      .then((resposne) => {
        setLoading(false);
        getTransactionListing(showFilter?.value);
        setDeleteModal(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleApproveWallet = () => {
    const payload = {
      ...transactionDetail,
      status: approveType.statusType,
      userId: approveType.userId,
    };
    updateTransactionDetail(payload, approveType.userId);
    toggleHandleApprove();
  };

  const getTransactionDetail = (id, type) => {
    getTrasactionById(id)
      .then((resposne) => {
        setLoading(false);
        setTransactionDetail(resposne?.data?.data);
        if (type === "APPROVED") {
          toggleHandleApprove();
          setApproveModal(true);
          setApprovalType({
            statusType: type,
            userId: id,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleTrasactionAccount = (type, mediaId) => {
    if (mediaId) {
      getTransactionDetail(mediaId, type);
      if (type === "REJECTED") {
        setDeleteModal(true);
        setDeleteType({
          updateType: type,
          updateId: mediaId,
        });
      }
    }
  };

  const getTransactionListing = (
    show,
    sortBy,
    startDate,
    endDate,
    search,
    page = 1
  ) => {
    const payloadforNonadmin = {
      email: isAdmin
        ? subscriberEmail
        : userRole !== "SUBSCRIBER"
        ? companyId
        : loggedUser?.email,
      goldiesAmount: 1,
      page: page,
      limit: show,
      status: sortBy,
      startDate: startDate,
      endDate: endDate,
      search,
    };

    const payloadforAdmin = {
      goldiesAmount: 1,
      page: page,
      limit: show,
      status: sortBy,
      startDate: startDate,
      endDate: endDate,
      search,
    };

    setLoading(true);

    const payload =
      isAdmin && !subscriberEmail ? payloadforAdmin : payloadforNonadmin;

    fetchTransactionListing(payload)
      .then((response) => {
        const { count, totalPages, page, withdraws, limit } =
          response?.data?.data;
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });
        const pList = parsingTransactionListingForAdmin(withdraws);
        setTransactionListing(
          dataForTransactionAdmin(pList, handleTrasactionAccount)
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const toggleHandleApprove = () => {
    setApproveModal((prev) => !prev);
  };

  const handleDeleteTransaction = (data) => {
    const payload = {
      ...transactionDetail,
      status: deleteType.updateType,
      rejectedReason: data.rejectedReason,
      userId: deleteType.updateId,
    };
    updateTransactionDetail(payload, deleteType.updateId);
  };

  return (
    <div>
      <TableBox
        data={transactionListing}
        enablePagination={enablePagination}
        selectTwoType="Status"
        optionValue1={showFilter}
        optionValue2={typeByFilter}
        handleDateChange={handleDateChange}
        options1={showFilterOptionsForMedia}
        onSearchChange={onSearchChange}
        handleSaveFilterVal={handleSaveFilterVal}
        options2={transactionStatus}
        headers={
          isAdmin && !subscriberEmail
            ? headersTransactionsForAdmin
            : headersForTransactions
        }
        paginationInfo={LocalPaginationInfo}
        onPageChange={onPageChange}
        isDateRange={true}
        title="Transactions"
        navTo="/transactions"
      />

      <CommonModal
        isOpen={isApproveOpen}
        toggle={toggleHandleApprove}
        showCross={false}
      >
        <ConfirmationPopup
          onClickbtn2={toggleHandleApprove}
          onClickbtn1={handleApproveWallet}
          title="You want to approve this transaction"
        />
      </CommonModal>

      <CommonModal
        isOpen={isDeleteOpen}
        toggle={() => setDeleteModal(false)}
        className="for-media-popup"
        showCross={true}
      >
        <DeleteConfirmPopup
          formRef={formRef}
          setDeleteModal={(newVal) => setDeleteModal(newVal)}
          deleteTransaction={handleDeleteTransaction}
        />
      </CommonModal>

      <Loader loading={loading} />
    </div>
  );
};

export default TransactionAccount;
