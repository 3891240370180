import React, { useState } from "react";
import RForm from "../RForm";
import { addMediaCompanyFields } from "../../utilities/static";
import RButton from "../RButton";
import RCheckBox from "../RCheckBox";
import "./styles.scss";
import ImgUploader from "../ImgUploader";
import useStore from "../../store/store";

const AddMediaCompany = ({
  imgKey = "",
  formRef,
  editPopup,
  getKeyOfImg,
  isEmbedMessage,
  mediaDetail = {},
  getMediaFormData,
}) => {
  const [isPrivacyCheck, setPrivacy] = useState(false);
  const { imgError, loading } = useStore();

  const handleSubmit = () => {
    if (!editPopup ? isPrivacyCheck : true) formRef?.current?.check();
  };

  const isFormDisabled = !editPopup  ? !isPrivacyCheck || loading  : loading;
  const disabledState = editPopup ? loading : isFormDisabled;

  return (
    <div className="add-media-company">
      <h1>{!editPopup ? "Add Media Company" : "Edit Media Company"}</h1>
      <div className="lowMedium" style={{ color: "#27272E" }}>
        {!editPopup
          ? "Create Media Company Account"
          : "Edit Media Company Account"}
      </div>
      {/* {isEmbedMessage} */}
      <ImgUploader getKeyOfImg={getKeyOfImg} imgKey={imgKey} />
      {imgError && <div className="error">{imgError}</div>}
      <RForm
        ref={formRef}
        formList={addMediaCompanyFields(mediaDetail, editPopup)}
        isFormForMedia={true}
        handleSubmit={getMediaFormData}
      />
      {!editPopup && (
        <div className="terms-condition flex">
          <RCheckBox
            checked={isPrivacyCheck}
            onClick={() => setPrivacy(!isPrivacyCheck)}
          />
          <span className="tc-txt">
            By creating an account means you agree to the
            <span className="bold"> Terms and Conditions, </span>
            and our
            <span className="bold"> Privacy Policy</span>
          </span>
        </div>
      )}
      <RButton
        type="submit"
        isSpinner={true}
        onClick={handleSubmit}
        disabled={disabledState}
        styles={{ marginTop: "39px" }}
        btnText={!editPopup ? "Create Account" : "Save"}
      />
    </div>
  );
};

export default AddMediaCompany;
