import "./styles.scss";
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 39 }} spin />

const Loader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="flex-container overlay">
          <Spin indicator={antIcon} style={{ color: '#fa1c3c' }}/>
        </div>
      )}
    </>
  );
};

export default Loader;
