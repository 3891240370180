import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, FormGroup, Label, Input, Badge } from "reactstrap";
import classNames from "classnames";
import { checkAllFieldsVlidation } from "../../utilities/functions/common";
import { parseFormData } from "../../utilities/functions/parsers";
import RSelect from "../RSelect";
import "./styles.scss";

const RForm = forwardRef((props, ref) => {
  const [fList, setList] = useState(props.formList);
  const [isEyeOn, setEyeOn] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [walletAddressVal, setWalletAddress] = useState("")
  const MAX_CHARACTER_LIMIT = 200;

  const onChnageEye = () => {
    setEyeOn((prev) => !prev);
    setList((prev) => {
      return {
        ...prev,
        password: {
          ...prev["password"],
          type: !isEyeOn ? "text" : "password",
        },
      };
    });
  };

  const onChnage = (e) => {
    const inputValue = e.target.value;
    setList((prev) => {
      return {
        ...prev,
        [e.target.name]: { ...prev[e.target.name], value: e.target.value },
      };
    });

    updateWordCount(inputValue);
  };
  
  const updateWordCount = (value) => {
    const input = value || (fList?.textarea?.value ?? "");
    setWordCount(input.length);
  };

  const onChangeCountry = (val) => {
    setList((prev) => {
      return {
        ...prev,
        country: { ...prev["country"], value: val },
      };
    });
  };

  const makefieldsClear = () => {
    let localList = fList;
    Object.keys(fList).forEach((fieldKey) => {
      localList = {
        ...localList,
        [fieldKey]: {
          ...localList[fieldKey],
          value: "",
        },
      };
    });
    setList(localList);
  };

  const onChangeWallet = (val) => {
    setWalletAddress(val.value)
    setList((prev) => {
      return {
        ...prev,
        walletName: { ...prev["walletName"], value: val },
        walletAddress: { ...prev["walletAddress"], value: val.value }
      };
    });
  };

  const onChangeapprovalTime = (val) => {
    setList((prev) => {
      return {
        ...prev,
        withdrawApprovalTime: { ...prev["withdrawApprovalTime"], value: val },
      };
    });
  };

  const onChangeForValue = (val) => {
    setList((prev) => {
      return {
        ...prev,
        withdrawLimitPeriod: { ...prev["withdrawLimitPeriod"], value: val },
      };
    });
  };

  useImperativeHandle(ref, () => {
    const { isFormForMedia = false } = props;
    return !isFormForMedia ? check : { check, makefieldsClear };
  });

  const check = (e) => {
    let localList = fList;
    Object.keys(fList).forEach((fieldKey) => {
      if (fList[fieldKey].required && fList[fieldKey].value === "") {
        localList = {
          ...localList,
          [fieldKey]: {
            ...localList[fieldKey],
            isRequireError: true,
            isInvalidError: false,
          },
        };
        setList(localList);
      } else if (fList[fieldKey].value !== "") {
        localList = {
          ...localList,
          [fieldKey]: {
            ...localList[fieldKey],
            isRequireError: false,
            isInvalidError:
              typeof fList[fieldKey].regex === "function"
                ? fList[fieldKey].regex(fList[fieldKey]?.value)
                : false,
          },
        };
        setList(localList);
      }
    });


    if (!checkAllFieldsVlidation(Object.values(localList))) {
      const list = parseFormData(localList);
      props.handleSubmit(list);
    }
  };

  const formHandle = (e) => {
    e.preventDefault();
  };
  

  return (
    <Form className="r-form" id="c-form" onSubmit={formHandle}>
      {Object.keys(fList)?.map((listKey) => (
        <FormGroup className="form-group" key={fList[listKey].name}>
          <div className="inner-form">
            <Label>{fList[listKey].label}</Label>
            {fList[listKey]?.name !== "country" &&
              fList[listKey]?.name !== "withdrawApprovalTime" &&
              fList[listKey]?.name !== "withdrawLimitPeriod" &&
              fList[listKey]?.name !== "walletName" && (
                <div className="inputwrapper" style={{ position: "relative" }}>
                  {fList[listKey]?.type === "textarea" && (
                    <>
                      <sub>
                        <span className="char-count">
                          {wordCount >= 0
                            ? `${wordCount}/${MAX_CHARACTER_LIMIT}`
                            : "Word Limit Exceeded"}
                        </span>
                      </sub>
                    </>
                  )}
                  <Input
                    readOnly={fList[listKey]?.readonly}
                    type={fList[listKey].type}
                    name={fList[listKey].name}
                    disabled={fList[listKey]?.disabled ?? false}
                    className={classNames("input-cl", {
                      "field-error-style":
                        fList[listKey].isRequireError ||
                        fList[listKey].isInvalidError,
                    })}
                    value={fList[listKey]?.name === "walletAddress" ? walletAddressVal :  fList[listKey].value}
                    placeholder={fList[listKey].placeholder}
                    onChange={(e) => fList[listKey]?.name === "walletAddress" ? "" : onChnage(e)}
                    maxLength={fList[listKey].maxLength}
                  />
                  {fList[listKey]?.name === "withdrawAmount" && (
                    <>
                      <Badge className="text-warning goldies-badge">
                        Goldies
                      </Badge>
                      <sub style={{ color: "#425466" }}>
                        <span style={{ marginRight: ".15rem" }}>
                          <img src="/assets/icons/Text.svg" alt="conversion"/>
                        </span>
                        {fList[listKey].value / props.goldiesInGLDY} GLDY
                      </sub>
                    </>
                  )}
                </div>
              )}
          </div>
          {(fList[listKey]?.name === "country" ||
            fList[listKey]?.name === "withdrawApprovalTime" ||
            fList[listKey]?.name === "withdrawLimitPeriod" ||
            fList[listKey]?.name === "walletName") && 
            (
            <RSelect
              width="100%"
              isError={
                fList[listKey].isRequireError || fList[listKey].isInvalidError
              }
              value={fList[listKey]?.value}
              showPortal={false}
              isSearchable={fList[listKey]?.name === "country" ? true : false}
              className={`input-select-fo ${fList[listKey]?.name === "withdrawLimitPeriod" && "withdrawSelect"}`}
              onChange={
                fList[listKey]?.name === "country"
                  ? onChangeCountry
                  : fList[listKey]?.name === "walletName"
                  ? onChangeWallet
                  : fList[listKey]?.name === "withdrawApprovalTime"
                  ? onChangeapprovalTime
                  : onChangeForValue
              }
              options={fList[listKey]?.options}
              placeholder={fList[listKey].placeholder}
            />
          )}
          <div className="inner-form">
            {fList[listKey].name === "password" &&
              !fList[listKey]?.disabled && (
                <img
                  src={
                    !isEyeOn
                      ? "/assets/icons/cross-eyeSlash.svg"
                      : "/assets/icons/eye.svg"
                  }
                  onClick={onChnageEye}
                  className={classNames("eye-icon-img", {
                    "field-error":
                      fList[listKey].isRequireError ||
                      fList[listKey].isInvalidError,
                  })}
                  alt="eye-icon"
                />
              )}
            <div className="flex space-between error-d">
              {fList[listKey].isRequireError && (
                <div className="flex">
                  <img
                    src="/assets/icons/exclamation-circle.svg"
                    alt="circle"
                  />
                  <div className="error">{fList[listKey]?.requireError}</div>
                </div>
              )}
              {fList[listKey].isInvalidError && (
                <div className="flex">
                  <img
                    src="/assets/icons/exclamation-circle.svg"
                    alt="circle"
                  />
                  <div className="error">{fList[listKey]?.invalidError}</div>
                </div>
              )}
              {fList[listKey]?.showChangeTxt && (
                <div className="small" onClick={props.handlePassword}>
                  {fList[listKey]?.changeTxt}
                </div>
              )}
            </div>
          </div>
        </FormGroup>
      ))}
    </Form>
  );
});

export default RForm;
