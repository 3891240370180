import React from "react";
import "./style.scss";
import TopBar from "../../hoc/TopBar";
import TransactionAccount from "../../components/TransactionDetail";

const TransactionListing = ({ enablePagination = true, listing }) => {
  return (
    <div className="transaction-listing">
      {enablePagination && (
        <TopBar>
          <div className="flex space-between media-cmp-row">
            <h1>Transactions</h1>
          </div>
        </TopBar>
      )}
      <div className="inner-ctn">
      <TransactionAccount enablePagination={enablePagination} listing={listing}/>
      </div>

      
    </div>
  );
};

export default TransactionListing;
