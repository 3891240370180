import React from "react";
import SideBar from "../../hoc/Sidebar";
import Content from "../Content";
import { Navigate } from "react-router-dom";
import roleStore from "../../store/role-store";


const AdminLayout = () => {
  const loggedIn = localStorage.getItem("XAUTH");
  const userRole = roleStore();

  if (!loggedIn) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div style={{ display: "flex" }}>
      {userRole.userRole !== "SUBSCRIBER" ? <SideBar /> : ""}
      <Content />
    </div>
  );
};

export default AdminLayout;
