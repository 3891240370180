import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import TableBox from "../../components/TableBox";
import {
  dataShapeForActivityLog,
  headersForActivity,
  showFilterOptionsForMedia,
  typeFilterOptionsForActivity,
} from "../../utilities/static";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import {
  fetchActivityLogs,
  fetchAllMediaAgencies,
  fetchSubscribersListing,
} from "../../apis/apis";
import { parseActivityLogs } from "../../utilities/functions/parsers";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import { throughError } from "../../utilities/functions/common";
import roleStore from "../../store/role-store";

const ActivityLog = () => {
  let navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(showFilterOptionsForMedia[0]);
  const [typeByFilter, setTypeByFilter] = useState(
    typeFilterOptionsForActivity[0]
  );
  const { loading, setLoading } = useStore();
  const [showMediaAgency, setShowMediaAgency] = useState();
  const [showSubscribers, setShowSubscribers] = useState();
  const [mediaAgencies, setMediaAgencies] = useState([]);
  const [activityData, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [subscribersListing, setSubscribersListing] = useState([]);
  const { loggedUser, isAdmin  } = roleStore();

  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getActivityLogs(
      showFilter?.value,
      typeByFilter?.value,
      searchValue,
      showMediaAgency?.label,
      showSubscribers?.label,
      loPage + +data?.selected
    );

    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  const getActivityLogs = (
    show,
    type,
    search,
    company = "",
    subscriber = "",
    page = 1
  ) => {
    const params = { show, type, search, company, subscriber, page };
    setLoading(true);
    fetchActivityLogs(params, loggedUser)
      .then((response) => {
        const { count, totalPages, page, data, limit } = response?.data?.data;
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });
        const parseData = parseActivityLogs(data);
        setData(parseData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  useEffect(() => {
    getActivityLogs(
      showFilter?.value,
      typeByFilter?.value,
      searchValue,
      showMediaAgency?.label,
      showSubscribers?.label
    );
    getAllMediaAgencies();
    getAllSubscribers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllMediaAgencies = (limit = 999999999999, page = 1) => {
    setLoading(true);

    const params = { limit, page };

    fetchAllMediaAgencies(params)
      .then((response) => {
        const formattedAgencies = response?.data?.data?.mediaAgencies?.map(
          (agency) => ({
            value: agency._id,
            label: agency.mediaCompany,
          })
        );
        const allOption = { label: "ALL", value: "" };
        setMediaAgencies([allOption, ...formattedAgencies]);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const getAllSubscribers = (limit = 999999999999, page = 1) => {
    const params = { limit, page };
    setLoading(true);

    fetchSubscribersListing(params)
      .then((response) => {
        const formattedSubscribers = response?.data?.data?.data?.map(
          (subscribers) => ({
            label: subscribers.firstName + " " + subscribers.lastName,
            value: subscribers._id,
          })
        );
        const allOption = { label: "ALL", value: "" };
        setSubscribersListing([allOption, ...formattedSubscribers]);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleSaveFilterVal = (value, type) => {
    setCurrentPage(0);
    if (type === "Show") {
      setShowFilter(value);
      getActivityLogs(
        value?.value,
        typeByFilter?.value,
        searchValue,
        showMediaAgency?.label,
        showSubscribers?.label
      );
    } else if (type === "Company") {
      setShowMediaAgency(value?.label === "ALL" ? "" : value);
      getActivityLogs(
        showFilter?.value,
        typeByFilter?.value,
        searchValue,
        value?.label === "ALL" ? "" : value?.label,
        showSubscribers?.label
      );
    } else if (type === "Subscriber") {
      setShowSubscribers(value?.label === "ALL" ? "" : value);
      getActivityLogs(
        showFilter?.value,
        typeByFilter?.value,
        searchValue,
        showMediaAgency?.label,
        value?.label === "ALL" ? "" : value?.label
      );
    } else {
      setTypeByFilter(value);
      getActivityLogs(
        showFilter?.value,
        value?.value,
        searchValue,
        showMediaAgency?.label,
        showSubscribers?.label
      );
    }
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    getActivityLogs(
      showFilter?.value,
      typeByFilter?.value,
      e.target.value,
      showMediaAgency?.label,
      showSubscribers?.label
    );
  };

  const LocalPaginationInfo = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: activityData?.length,
  };

  return (
    <div className="activity-log">
      <TopBar>
        <div className="">
          <h1>Activity Log</h1>
        </div>
      </TopBar>
      <div className="inner-ctn">
        <TableBox
          selectTwoType="Type"
          searchValue={searchValue}
          optionValue1={showFilter}
          optionValue2={typeByFilter}
          enablePagination={true}
          companysOnboard={isAdmin && true}
          mediaAgencies={mediaAgencies}
          subscribers={subscribersListing}
          onPageChange={onPageChange}
          headers={headersForActivity}
          onSearchChange={onSearchChange}
          paginationInfo={LocalPaginationInfo}
          options1={showFilterOptionsForMedia}
          options2={typeFilterOptionsForActivity}
          handleSaveFilterVal={handleSaveFilterVal}
          data={dataShapeForActivityLog(activityData, navigate)}
        />
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default ActivityLog;
