import React, { useEffect, useRef } from "react";
import RForm from "../../components/RForm";
import { resetPasswordFields } from "./data";
import RButton from "../../components/RButton";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPasswordByToken } from "../../apis/apis";
import { throughError } from "../../utilities/functions/common";
import { toast } from "react-toastify";
import useStore from "../../store/store";
import "./styles.scss";
import Loader from "../../components/Loader";
import { isNull } from "lodash";

const PasswordChangeReset = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const forNewPassword = queryParams.get("new");

  const { setLoading, loading } = useStore();
  const formRef = useRef(null);

  let navigate = useNavigate();

  useEffect(() => {
    if (isNull(token)) toast.error("Token is missing.");
  }, [token]);

  const handleSubmit = () => {
    formRef.current();
  };

  const resetPassword = (payload) => {
    resetPasswordByToken(payload)
      .then((response) => {
        toast.success(response?.data?.data?.message);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const submit = (data) => {
    if (data?.newPassword !== data?.confirmPassword) {
      toast.error("New and Confirm Password are not same.");
    } else {
      if (token) {
        setLoading(true);
        const payload = {
          resetToken: token ?? "",
          password: data?.newPassword,
          confirmPassword: data?.confirmPassword,
        };
        resetPassword(payload);
      } else toast.error("Token is missing.");
    }
  };

  return (
    <div className="reset-password-ctn">
      <div className="reset-form">
        <RForm
          formList={resetPasswordFields}
          ref={formRef}
          handleSubmit={submit}
        />
        <RButton
          type="submit"
          btnText={!forNewPassword ? "Reset Your Password" : "Create Password"}
          styles={{ marginTop: "40px" }}
          onClick={handleSubmit}
        />
      </div>

      {!forNewPassword && (
        <div className="reset-note mt-56">
          If you did not request this change, you do not need to do anything.
          Thanks,
        </div>
      )}
      <div className="img-div mt-24">
        <img src="/assets/icons/logo.png" alt="logo" style={{ width: '12rem' }}/>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default PasswordChangeReset;
