import { useEffect, useState } from "react";

const ReSizeWindow = () => {
  const [isMobileScreen, setMbilScreen] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 766) {
      setMbilScreen(false);
    } 
    if( window.innerWidth <= 620 ){
      setMbilScreen(true)
    }
  };
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobileScreen };
};

export default ReSizeWindow;
