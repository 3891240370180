import React from "react";
import RForm from "../RForm";
import RButton from "../RButton";
import { deleteTransactionFields } from "../../utilities/static";

const index = ({ formRef,   deleteTransaction }) => {
  const handleSubmit = () => {
    formRef?.current?.check();
  };
  return (
    <div className="delete-popup">
      <h1>Transaction Rejected</h1>
      <div className="lowMedium" style={{ color: "#27272E" }}>
        Type the reason for rejection
      </div>

      <RForm
        ref={formRef}
        formList={deleteTransactionFields()}
        isFormForMedia={true}
        handleSubmit={deleteTransaction}
      />

      <RButton
        type="submit"
        onClick={handleSubmit}
        disabled={false}
        styles={{ marginTop: "39px" }}
        btnText={"Submit"}
      />
    </div>
  );
};

export default index;
