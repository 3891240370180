import React from "react";
import "./styles.scss";

const ActionBtns = ({
  status,
  canEdit = false,
  id,
  toggle,
  forSubscribers = false,
  forAccount = false,
  forTransactions = false,
}) => {
  return (
    <div className="action-btns flex">
      {canEdit && !forTransactions && (
        <div className="action" onClick={() => toggle("edit", id)}>
          Edit
        </div>
      )}
      {!forSubscribers && !forAccount && !forTransactions && (
        <div
          className="action"
          onClick={() =>
            toggle("status", id, status === "ACTIVE" ? "inactive" : "active")
          }
        >
          {status === "ACTIVE" ? "Inactive" : "Active"}
        </div>
      )}
      {forSubscribers && (
        <div className="action" onClick={toggle}>
          {status === "ACTIVE" ? "Inactive" : "Active"}
        </div>
      )}
      {forAccount && (
        <div className="action" onClick={() => toggle("delete", id)}>
          Delete
        </div>
      )}
      {/* {forTransactions && (
        <div className={status !== "PENDING" ? "disable action" : "action"}  onClick={status === "PENDING" ? () => toggle("APPROVED", id) : undefined}>
          Approve
        </div>
      )} */}
      {forTransactions && (
        <div className={status !== "PENDING" ? "disable action" : "action"} onClick={status === "PENDING" ? () => toggle("REJECTED", id) : undefined}>
          Reject
        </div>
      )}
    </div>
  );
};

export default ActionBtns;
