import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import TableBox from "../../components/TableBox";
import {
  dataShapeForCompany,
  getStartANdEndDate,
  headersForCompany,
  optionsForOnboard,
  showFilterOptionsForMedia,
  typeFilterOptionsForSubscribers,
} from "../../utilities/static";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty, uniqWith, isEqual, isUndefined } from "lodash";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import "./styles.scss";
import { fetchSubscribers, updateSubscriberStatus, fetchSubscribersListing } from "../../apis/apis";
import { getSubscribersParse } from "../../utilities/functions/parsers";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import CommonModal from "../../components/CommonModal";
import ConfirmationPopup from "../../components/MediaStatus";
import roleStore from "../../store/role-store";

const filterIndex = {
  null: 0,
  engage: 1,
  most: 2,
  not: 3,
};
const CompanyDetail = ({ showBreadCrumb = true, filter = "null" }) => {
  const { cmpid, compName } = useParams();
  const { loggedUser } = roleStore();

  let navigate = useNavigate();
  const agencyId = !isUndefined(cmpid) ? cmpid : loggedUser?._id;
  const [subsListing, setSubscribersListing] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [mediaAgencies, setMediaAgencies] = useState([]);
  // const [showMediaAgency, setShowMediaAgency] = useState()
  const { loading, setLoading } = useStore();
  const [showFilter, setShowFilter] = useState(showFilterOptionsForMedia[0]);
  const [timeframe, setTimeFrame] = useState(optionsForOnboard[0]?.label);
  const [oboardFilter, setOnBoardFilter] = useState();

  const [sortByFilter, setSortByFilter] = useState(
    typeFilterOptionsForSubscribers[filterIndex[filter] ?? 0]
  );
  const [searchValue, setSearchValue] = useState("");
  const [subscriberDetail, setSubscriberDetail] = useState(null);
  const [isOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState("ACTIVE");

  const crumbs = [
    {
      to: "/companies",
      txt: "Media Companies",
      active: false,
    },
    {
      to: "",
      txt: compName,
      active: false,
    },
  ];

  useEffect(() => {
    getSubsriberListing(
      sortByFilter?.value,
      showFilter?.value,
      searchValue,
      1,
      timeframe
    );
    getSubscribersListing()
    // eslint-disable-next-line
  }, []);

  const getSubscribersListing = (limit = 999999999999, page = 1) => {
    const params = { limit, page };
    setLoading(true);

    fetchSubscribersListing(params)
      .then((response) => {
        const formattedAgencies = response?.data?.data?.data?.map(
          (agency) => ({
            label: agency.firstName + ' ' + agency.lastName,
            value: agency._id,
          })
        );
        const allOption = { label: "ALL", value: "" };
        setMediaAgencies([allOption, ...formattedAgencies]);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTime = (timeframe) => {
    let queryDates = {};
    setTimeFrame(timeframe);
    if (timeframe !== "All") {
      queryDates = getStartANdEndDate(timeframe);
    } else {
      queryDates = {
        startDate: "",
        endDate: "",
      };
    }

    return queryDates;
  };

  const getSubsriberListing = (
    sortBy,
    show,
    search = "",
    page = 1,
    timeframe = "All"
  ) => {
    setLoading(true);
    const queryDates = handleTime(timeframe);
    const params = { agencyId, sortBy, show, search, page, ...queryDates };
    fetchSubscribers(params)
      .then((response) => {
        const { count, totalPages, page, data, limit } = response?.data?.data;
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });
        const parseData = getSubscribersParse(data);
        setSubscribersListing(parseData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getSubsriberListing(
      sortByFilter?.value,
      showFilter?.value,
      searchValue,
      loPage + +data?.selected
    );

    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  const handleSaveFilterVal = (value, type) => {
    setCurrentPage(0);
    if (type === "Show") {
      setShowFilter(value);
      getSubsriberListing(
        sortByFilter?.value,
        value?.value,
        searchValue,
        paginationInfo?.page,
        timeframe
      );
    }
    if (type === "Onboarded") {
      setOnBoardFilter(value);
      getSubsriberListing(
        sortByFilter?.value,
        showFilter?.value,
        value?.label,
        paginationInfo?.page,
        
      );
    } else {
      setSortByFilter(value);
      getSubsriberListing(
        value?.value,
        showFilter?.value,
        searchValue,
        paginationInfo?.page,
        timeframe
      );
    }
  };

  const LocalPaginationInfo = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: subsListing?.length,
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    getSubsriberListing(sortByFilter?.value, showFilter?.value, e.target.value);
  };

  const toggle = (subsDetail, status) => {
    setStatus(status?.toUpperCase());
    setSubscriberDetail(subsDetail ?? {});
    setModalOpen((prev) => !prev);
  };

  const updateStatusOfSubscriber = (subsDetail) => {
    const payload = {
      subscriberImage: subsDetail?.subscriberImg,
      subscriberImagePath: subsDetail?.subscriberImagePath,
      firstName: subsDetail?.firstName,
      lastName: subsDetail?.lastName,
      country: subsDetail?.country,
      email: subsDetail?.email,
      status: status,
      agencyId: subsDetail?.agencyId,
    };

    updateSubscriberStatus(subsDetail?.subsId, payload)
      .then((response) => {
        toggle({}, status);
        getSubsriberListing(
          sortByFilter?.value,
          showFilter?.value,
          searchValue
        );
      })
      .catch((err) => {
        toggle({}, status);
      });
  };

  const handleStatusPopup = () => {
    if (!isEmpty(subscriberDetail)) {
      updateStatusOfSubscriber(subscriberDetail);
    }
  };

  const handleNavigation = (detail) => {
    if (!showBreadCrumb) {
      navigate(
        `/wallet/${showBreadCrumb}/${detail?.subsId}/${detail?.subsName}/${detail?.email}`
      );
    } else
      navigate(
        `/wallet/${showBreadCrumb}/${detail?.subsId}/${detail?.subsName}/${cmpid}/${compName}/${detail?.email}`
      );
  };

  return (
    <div className="company-detail">
      <TopBar>
        <div className="">
          <h1>{showBreadCrumb ? "Media Companies" : "Subscribers"}</h1>
          {!isUndefined(agencyId) && showBreadCrumb && (
            <CustomBreadCrumb crumbs={uniqWith(crumbs, isEqual)} />
          )}
        </div>
      </TopBar>

      <div className="inner-ctn">
        <TableBox
          isOnBoardFilter={true}
          enablePagination={true}
          searchValue={searchValue}
          optionValue1={showFilter}
          oboardFilter={oboardFilter}
          onPageChange={onPageChange}
          optionValue2={sortByFilter}
          headers={headersForCompany}
          mediaAgencies={mediaAgencies}
          onSearchChange={onSearchChange}
          paginationInfo={LocalPaginationInfo}
          options1={showFilterOptionsForMedia}
          handleSaveFilterVal={handleSaveFilterVal}
          options2={typeFilterOptionsForSubscribers}
          
          data={dataShapeForCompany(
            subsListing,
            handleNavigation,
            toggle,
            false
          )}
        />
      </div>

      <CommonModal
        isOpen={isOpen}
        toggle={() => toggle({}, status)}
        showCross={false}
      >
        <ConfirmationPopup
          onClickbtn2={() => toggle({}, status)}
          onClickbtn1={handleStatusPopup}
          title={`You want to ${
            status === "ACTIVE" ? "active" : "in-active"
          } this user.`}
        />
      </CommonModal>

      <Loader loading={loading} />
    </div>
  );
};

export default CompanyDetail;
