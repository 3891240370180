import moment from "moment";

import { isUndefined } from "lodash";
import { toast } from "react-toastify";
import { API_SERVER_URL } from "../../config";

export function nFormatter(num, digits = 1) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "Q" },
  ];
  const rx = /^(\d+)(\.\d+)?([kmbt])?$/;

  let item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const makeFirtLetterCapital = (word) => {
  if (!isUndefined(word)) {
    return word?.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
};

export const IntoComma = (amount) => {
  return amount?.toLocaleString();
};

export const setMenusActive = (menus, menu) => {
  const pMenus = menus.map((men) => {
    if (men.title === menu) {
      return {
        ...men,
        isActive: true,
      };
    } else
      return {
        ...men,
        isActive: false,
      };
  });

  return pMenus;
};

export const parseMenusOnMount = (menus, pathName) => {
  let pMenus;
  if (pathName !== "/") {
    pMenus = menus.map((men) => {
      if (men.to !== "/" && pathName?.includes(men.to)) {
        return {
          ...men,
          isActive: true,
        };
      } else
        return {
          ...men,
          isActive: false,
        };
    });
  } else {
    pMenus = [
      { ...menus[0], isActive: true },
      ...menus.slice(1).map((men) => {
        return { ...men, isActive: false };
      }),
    ];
  }

  return pMenus;
};
export const subMenusHandle = (menus, pathName) => {
  const pMenus = menus.map((men) => {
    if (men.to === pathName) {
      return {
        ...men,
        isActive: true,
      };
    } else
      return {
        ...men,
        isActive: false,
      };
  });

  return pMenus;
};

export const checkAllFieldsVlidation = (list) => {
  let errorCount = 0;
  list?.forEach((lis) => {
    if (lis.isRequireError || lis.isInvalidError) {
      errorCount = errorCount + 1;
    }
  });

  return errorCount < 1 ? false : true;
};

export const formatDateTimeForActivityLog = (timestamp) => {
  return moment(timestamp).format("DD/MM/YYYY [at] h:mm A");
};

export const throughError = (error, navigate) => {
  if (error?.response?.status === 401) {
    localStorage.clear();
    navigate("/login");
  }
  toast.error(
    error?.response?.status === 401
      ? "Session expired. Please Login Again"
      : error?.response?.data?.message ?? "Something went wrong."
  );
};

export const getError = (error) => {
  return error?.response?.data?.message ?? "Something went wrong.";
};

export const getRecordsNumber = (limit, currentPage, pageLength = 0) => {
  if (currentPage === 1) {
    return `1 - ${pageLength}`;
  } else {
    return `${limit * (currentPage - 1) + 1} - ${
      limit * (currentPage - 1) + pageLength
    }`;
  }
};

const colorsObj = [
  {
    color: "#4C6FFF",
    backgroundColor: "#E1E8FF",
  },
  {
    color: "#F7936F",
    backgroundColor: "#FFEDE3",
  },
];

export function getRandomIndex() {
  const randomBytes = new Uint32Array(1);
  window.crypto.getRandomValues(randomBytes);
  const randomIndex = randomBytes[0] % colorsObj.length;
  return colorsObj[randomIndex];
}

export const getFirstWordUpperOFSetence = (str) => {
  const arr = str.split(" ");

  for (let i = 0; i < arr?.length; i++) {
    arr[i] = " " + arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr;
};

export const checkFilesFormat = async (files) => {
  const filesAllowed = ["jpg", "png", "jpeg", "JPG", "JPEG", "PNG"];
  let validationFailed = false;
  await files?.forEach((file) => {
    const fileExtension = file?.name.split("").reverse().join("").split(".")[0];
    if (!filesAllowed.includes(fileExtension.split("").reverse().join(""))) {
      validationFailed = true;
    }
  });
  return validationFailed;
};

export const makeImgkeyIntoUrl = (key) => {
  if (key) {
    return `${API_SERVER_URL}/media/${key}`;
  }
};

export const capitalizeWords = (sentence) => {
  const words = sentence.split(" ");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const capitalizedSentence = capitalizedWords.join(" ");
  return capitalizedSentence;
};
